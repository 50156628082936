import React from 'react'
import { Helmet } from 'react-helmet-async'

const SEO = ({Pagetitle,description,keywords}) => {
  return (
    <Helmet>
        
        <title>{Pagetitle || "Research Writebing"}</title>
        <meta name="description" content={description || 'Thesis writing,__research paper writing and more!'}/>
        <meta name="keywords" content={keywords || '_Research,writebing,_research paper'}/>
    </Helmet>
  )
}

export default SEO;