import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";
import "react-loading-skeleton/dist/skeleton.css";

import "./App.css";
import { BlogContext } from "./BlogContext";
import StarterSEO from "./component/SEO_Optimization/StarterSEO";
import PageTracker from "./component/GA_4/PageTracker";
import LoadingOverlay from "./component/LoadingSkeleton/LoadingOverlay";
import { ToastContainer } from "react-toastify";

ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);

// Lazy loaded components
const RHomepage = React.lazy(() => import("./page/RHomepage"));
const Blog = React.lazy(() => import("./page/Blog"));
const Service = React.lazy(() => import("./page/Services"));
const HWork = React.lazy(() => import("./page/HWork"));
const Privacy = React.lazy(() => import("./page/Privacy"));
const Contact = React.lazy(() => import("./page/Contact"));
const BlogAbout = React.lazy(() => import("./page/BlogAbout"));
const TermsAndConditions = React.lazy(() =>
  import("./page/TermsAndConditions")
);
const AboutUs = React.lazy(() => import("./page/AboutUs"));
const NewPage = React.lazy(() => import("./page/NewPage"));
const BlogHome = React.lazy(() => import("./page/BlogSection/BlogHome"));
const CreateBlog = React.lazy(() => import("./page/BlogSection/CreateBLog"));
const ShowBlog = React.lazy(() => import("./page/BlogSection/ShowBlog"));
const ListAllBlog = React.lazy(() => import("./page/BlogSection/ListAllBlog"));
const EditBlog = React.lazy(() => import("./page/BlogSection/EditBlog"));
const AppBar = React.lazy(() =>
  import("./page/BlogSection/Editor/LexicalEditorWrapper/Index")
);

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <BlogContext>
          <div className="App">
            <StarterSEO />
            <PageTracker />
            <Suspense fallback={<LoadingOverlay />}>
              <Routes>
                <Route exact path="/" element={<RHomepage />} />

                <Route path="/blog-page" element={<Blog />} />
                <Route path="/service" element={<Service />} />
                <Route path="/how-we-work" element={<HWork />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/about-blog/:id" element={<BlogAbout />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/faq" element={<NewPage />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/blog" element={<BlogHome />} />
                <Route path="/blogs" element={<BlogHome />} />
                <Route path="/blog/:slug" element={<ShowBlog />} />
                <Route path="/blogs/:slug" element={<ShowBlog />} />
                <Route path="/edit-blog" element={<EditBlog />} />
                <Route path="/create-blog" element={<CreateBlog />} />
                <Route path="/all-blog/:pageIndex" element={<ListAllBlog />} />
                <Route path="/all-blog" element={<ListAllBlog />} />
                <Route path="/editor" element={<AppBar />} />
              </Routes>
            </Suspense>
            <ToastContainer />
          </div>
        </BlogContext>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
