import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const ScrollTracker = () => {
  const location = useLocation("");

  useEffect(() => {
    let scrollTimeout;

    const trackScrollDepth = () => {
      const scrollDepths = [25, 50, 75, 100];
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercentage = (scrollTop / scrollHeight) * 100;

      scrollDepths.forEach(depth => {
        const scrollKey = `${location.pathname}_scrolled_${depth}`;
        if (scrollPercentage >= depth && !sessionStorage.getItem(scrollKey)) {
            //console.log(`${depth}% page covered.`);
          ReactGA.event({
            category: 'Scroll',
            action: `Scrolled ${depth}%`,
            label: `User scrolled to ${depth}% of the page ${location.pathname}`
          });
          sessionStorage.setItem(scrollKey, 'true');
        }
      });
    };

    const handleScroll = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(trackScrollDepth, 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return null; // This component doesn't render anything
};

export default ScrollTracker;
